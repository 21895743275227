import { ChangeDetectionStrategy, Component, computed, OnInit, Renderer2 } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { HlmToasterModule } from '@recapp/ui';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { State, Storage } from './core/store/storage-meta';

@Component({
    standalone: true,
    imports: [RouterModule, HlmToasterModule, AngularQueryDevtools],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    isLocalhost = computed(() => window.location.hostname === 'localhost');

    constructor(
        private readonly renderer: Renderer2,
        private readonly store: Store<State>
    ) {}

    ngOnInit() {
        this.renderer.listen('window', 'storage', (event) => {
            this.store.dispatch(new Storage(event.key));
        });
    }
}
